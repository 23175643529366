:root {
  --primary: rgb(13, 109, 182);
  --secondary: rgb(255, 128, 0);
  --primary-rgb: 13, 109, 182;
  --secondary-rgb: 255, 128, 0;
}

.App {
  min-height: calc(100vh - 64px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('../../App/app/common/fonts/Roboto-Regular.ttf') format('truetype');
}